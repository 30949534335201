body {
  font-family: monospace;
  font-size: 13px;
  font-weight: normal;
  overflow-y: scroll;
}

button,
input,
select,
textarea {
  font-family: monospace;
  font-size: inherit;
  font-weight: inherit;
  border: 0;
  outline: 0;
  background: transparent;
  padding: 0;
}

h1,
h2,
p,
pre {
  margin: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Layout */

.app {
  min-height: 100dvh;
  max-width: 976px;
  margin: 0 auto;
  padding-bottom: calc(1rem + 40px);
}

.block {
  padding: 1rem;
  border-top: 2px solid rgba(0, 0, 0, 0.125);
}

/* Header */

.header__base {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

.header__title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
}

/* User Card */

.userCard__base {
  position: relative;
  margin-top: 1rem;
  display: flex;
  border: 2px solid black;
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
}

.userCard__img {
  flex: 0 0 88px;
  width: 88px;
  height: 88px;
  background: rgba(0, 0, 0, 0.125);
  border-right: 2px solid black;
}

.userCard__authIndicator {
  position: absolute;
  bottom: 0;
  left: 88px;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  transform: translate(-50%, -4px);
  border: 2px solid white;
  box-shadow: inset 0 0 0 2px black;
  box-sizing: content-box;
}

.userCard__list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
  padding: 0 1rem;
  overflow: hidden;
}

.userCard__list > li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.userCard__expandButton {
  flex: 0 0 48px;
  width: 48px;
  height: auto;
  border-left: 2px solid black;
  font-size: 1.5rem;
  cursor: zoom-in;
}

.userCard__expandButton[aria-expanded="true"] {
  cursor: pointer;
}

.userCard__code {
  margin-top: 1rem;
  padding: 1rem;
  border: 2px solid black;
  border-radius: 4px;
  overflow-x: scroll;
  max-width: 100%;
}

/* Test Button */

.testButtons__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  gap: 1rem;
}

.testButton__base {
  position: relative;
}

.testButton__base:not(:has(.testButton__button:disabled)):hover::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: -8px;
  background: rgba(0, 0, 0, 0.0625);
  border-radius: 4px;
}

.testButton__base.testButton--isExpanded {
  /* grid-column: 1 / -1; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(40px, 40px));
  gap: 1rem;
}

.testButton__base.testButton--isExpanded > .testButton__content {
  /* grid-column: 1 / -1; */
}

.testButton__buttonWrapper {
  position: relative;
  font-weight: bold;
}

.testButton__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.testButton__button {
  border: 2px solid black;
  background: white;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  cursor: pointer;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}

.testButton__button:disabled {
  color: #777;
  opacity: 0.25;
  cursor: not-allowed;
}

.testButton__button:not(:disabled):hover > .testButton__text {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.testButton__button.testButton--isLoading {
  cursor: wait;
}

.testButton__indicator {
  font-size: 1.25rem;
}

.testButton__indicator {
  margin-right: 0.5rem;
}

.testButton__elapsed,
.testButton__expandButton {
  position: absolute;
  left: -6px;
  background: white;
  padding: 0 4px;
  border-radius: 4px;
  border: 2px solid black;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testButton__elapsed {
  top: -6px;
}

.testButton__expandButton {
  bottom: -6px;
  cursor: pointer;
}

.testButton__expandButton:not(:disabled):hover {
  background: black;
  color: white;
}

/* Inputs */

.input__base {
  position: relative;
}

.input__input {
  border: 2px solid black;
  background: white;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}

.input__input[readonly],
a.input__input {
  box-shadow: 0 0 0 2px #0b3;
}

.input__input[aria-invalid],
a:empty {
  box-shadow: 0 0 0 2px #f00;
}

textarea.input__input {
  padding: 0.75rem 0.5rem 0.75rem;
  min-height: 80px;
  resize: vertical;
  overflow-y: scroll;
  word-break: break-all;
}

a.input__input {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.input__encoding,
.input__expandButton {
  position: absolute;
  background: white;
  padding: 0 4px;
  border-radius: 4px;
  border: 2px solid black;
  font-weight: bold;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input__encoding {
  left: 12px;
  bottom: -6px;
  font-size: 0.75rem;
}

.input__expandButton {
  left: -6px;
  bottom: -6px;
  cursor: pointer;
}

.input__expandButton:hover {
  background: black;
  color: white;
}

/* LogItems */

.logItems__base {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #ddd;
}

.logItems__base.logItem--sticky {
  position: sticky;
  bottom: 0;
  max-height: 33.3333vh;
  overflow-y: scroll;
}

/* LogItem */

.logItem__base {
  border: 2px solid black;
  border-radius: 4px;
  background: white;
}

.logItem__button.logItem--isLoading {
  cursor: wait;
}

.logItem__header {
  display: flex;
  align-items: center;
  border-bottom: 2px solid black;
  min-height: 42px;
}

.logItem__indicator {
  font-size: 1.25rem;
  padding: 0.25rem 0.5rem;
}

.logItem__text {
  font-weight: bold;
  margin-right: auto;
}

.logItem__txLink,
.logItem__copy,
.logItem__elapsed {
  padding: 0 8px;
  border-left: 2px solid black;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
}

.logItem__txLink,
.logItem__copy {
  cursor: pointer;
}

.logItem__result {
  padding: 1rem;
  overflow-x: auto;
  width: 100%;
}

.logItem__result + .logItem__result {
  border-top: 2px solid black;
}

.logItem__result.logItem--expand {
  white-space: normal;
  word-break: break-all;
}

/* Footer */

.footer__base {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  border-top: 2px solid rgba(0, 0, 0, 0.25);
}

.footer__actionsWrapper {
  position: fixed;
  max-width: 976px;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 40px 16px 0;
  pointer-events: none;
  display: flex;
  justify-content: end;
  gap: 8px;
}

.footer__actionsWrapper.footer--withGradient {
  background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
}

.footer__actions {
  position: relative;
  display: flex;
}

.footer__action {
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid black;
  border-bottom: 0;
  background: white;
  pointer-events: auto;
}

.footer__action:first-child {
  border-radius: 4px 0 0 0;
}

.footer__action:last-child {
  border-radius: 0 4px 0 0;
}

.footer__action + .footer__action {
  margin-left: -2px;
}

.footer__actionText,
.footer__actionImg {
  transition: transform linear 150ms;
}

.footer__actionText {
  font-size: 16px;
}

.footer__actionImg {
  width: 16px;
  height: 16px;
}

.footer__action:hover > .footer__actionText,
.footer__action:hover > .footer__actionImg {
  transform: scale(1.1);
}
